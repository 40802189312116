import Spinner from "@/components/Spinner";
import SuspenseOutlet from "@/components/SuspenseOutlet";
import { useAuth } from "@/contexts/AuthHook";
import { Navigate, useLocation } from "react-router-dom";

/**
 * AttorneyLayout - A layout component for attorney routes
 * Provides authentication protection with the minimal styling from MinimalLayout
 */
const AttorneyLayout: React.FC = () => {
  const { session, isLoading, user } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <Spinner />;
  }

  if (!session || !user) {
    return (
      <Navigate
        to="/auth/sign-in-with-magic-link"
        replace
        state={{ from: location }}
      />
    );
  }

  return (
    <div className="min-h-screen">
      <SuspenseOutlet />
    </div>
  );
};

export default AttorneyLayout;
