import {
  SupabaseClient,
  RealtimeChannel,
  RealtimePostgresChangesPayload,
  Session,
} from "@supabase/supabase-js";
import { BaseFeatureFlagService, FeatureFlagData } from "./featureFlags";

type FeatureFlagsChangeCallback = (
  featureFlags: Map<string, FeatureFlagData>,
) => void;

type Feature = {
  id: number;
  name: string;
  default_state: boolean;
};

type FeatureFlag = {
  feature_id: number;
  is_enabled: boolean;
  trial_end_date: string | null;
};

export class SupabaseFeatureFlagService extends BaseFeatureFlagService {
  private supabase: SupabaseClient;
  private featureFlagsCache: Map<string, FeatureFlagData> = new Map();
  private listeners: RealtimeChannel[] = [];
  private featureFlagsChangeCallbacks: Set<FeatureFlagsChangeCallback> =
    new Set();
  private isLoadingChangeCallbacks: Set<(isLoading: boolean) => void> =
    new Set();
  private featureIdToName: Map<number, string> = new Map();
  private isLoading: boolean = true;
  private userFlagsMap: Map<number, FeatureFlag> = new Map();
  private firmFlagsMap: Map<number, FeatureFlag> = new Map();
  private featureDefaultsMap: Map<number, boolean> = new Map();

  constructor(config: {
    userId?: string;
    firmId?: string | null;
    supabaseClient: SupabaseClient;
  }) {
    super(config);
    this.supabase = config.supabaseClient;
    this.setupVisibilityChangeListener();
    this.setupAuthListener();
  }

  private setupAuthListener() {
    const {
      data: { subscription },
    } = this.supabase.auth.onAuthStateChange((event, session) => {
      if (
        event === "SIGNED_IN" ||
        event === "TOKEN_REFRESHED" ||
        event === "USER_UPDATED"
      ) {
        if (session?.user.id && session.user.id !== this.userId) {
          console.log("Handling user change:", session.user.id);
          void this.handleUserChange(session);
        }
      } else if (!session) {
        console.log("User signed out, resetting feature flags");
        this.userId = undefined;
        this.firmId = undefined;
        this.featureFlagsCache.clear();
        this.featureIdToName.clear();
        this.featureDefaultsMap.clear();
        this.userFlagsMap.clear();
        this.firmFlagsMap.clear();
        this.notifyFeatureFlagsChanged();
        this.setIsLoading(false);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }

  private async handleUserChange(session: Session) {
    this.setIsLoading(true);

    this.userId = session.user.id;

    const { data: user, error } = await this.supabase
      .from("user")
      .select("firm_id")
      .eq("id", session.user.id)
      .single();

    const oldFirmId = this.firmId;
    this.firmId = user?.firm_id;

    // If firmId changed, we need to reload feature flags
    if (oldFirmId !== this.firmId) {
      void this.loadInitialFeatureFlags();
    }
  }

  public async handleExternalUpdate(config: {
    userId?: string;
    firmId?: string | null;
  }) {
    const oldFirmId = this.firmId;
    const oldUserId = this.userId;

    this.userId = config.userId;
    this.firmId = config.firmId ?? null;

    // If either userId or firmId changed, reload feature flags
    if (oldFirmId !== this.firmId || oldUserId !== this.userId) {
      await this.loadInitialFeatureFlags();
    }
  }

  private setupVisibilityChangeListener() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.handleVisibilityChange();
      }
    });
  }

  private async handleVisibilityChange() {
    const channels = this.supabase.getChannels();

    for (const channel of channels) {
      if (channel.state !== "joined") {
        console.log(`Re-subscribing to channel ${channel.topic}`);
        try {
          await this.subscribeToChannel(channel);
        } catch (err) {
          console.error(
            `Error re-subscribing to channel ${channel.topic}`,
            err,
          );
        }
      }
    }
  }

  private subscribeToChannel(channel: RealtimeChannel): Promise<void> {
    return new Promise((resolve, reject) => {
      channel.subscribe((status, err) => {
        if (status === "SUBSCRIBED") {
          resolve();
        } else if (status === "CHANNEL_ERROR" || status === "TIMED_OUT") {
          reject(err);
        }
      });
    });
  }

  async loadInitialFeatureFlags(): Promise<void> {
    try {
      this.setIsLoading(true);
      const identifier = this.getIdentifier();

      // Fetch all features
      const { data: features, error: featureError } = await this.supabase
        .from("feature")
        .select("id, name, default_state");

      if (featureError) {
        throw new Error(`Error fetching features: ${featureError.message}`);
      }

      if (features) {
        // Build maps from featureId to featureName and default_state
        for (const feature of features) {
          this.featureIdToName.set(feature.id, feature.name);
          this.featureDefaultsMap.set(feature.id, feature.default_state);
        }

        // Fetch user and firm feature flags in parallel
        const [userFeatureFlagsResult, firmFeatureFlagsResult] =
          await Promise.all([
            identifier.userId
              ? this.supabase
                  .from("user_feature_flag")
                  .select("feature_id, is_enabled, trial_end_date")
                  .eq("user_id", identifier.userId)
              : Promise.resolve({ data: [], error: null }),
            identifier.firmId
              ? this.supabase
                  .from("firm_feature_flag")
                  .select("feature_id, is_enabled, trial_end_date")
                  .eq("firm_id", identifier.firmId)
              : Promise.resolve({ data: [], error: null }),
          ]);

        if (userFeatureFlagsResult.error) {
          throw new Error(
            `Error fetching user feature flags: ${userFeatureFlagsResult.error.message}`,
          );
        }
        if (firmFeatureFlagsResult.error) {
          throw new Error(
            `Error fetching firm feature flags: ${firmFeatureFlagsResult.error.message}`,
          );
        }

        const userFeatureFlags = userFeatureFlagsResult.data || [];
        const firmFeatureFlags = firmFeatureFlagsResult.data || [];

        // Populate the separate maps
        this.userFlagsMap = new Map(
          userFeatureFlags.map((flag) => [flag.feature_id, flag]),
        );
        this.firmFlagsMap = new Map(
          firmFeatureFlags.map((flag) => [flag.feature_id, flag]),
        );

        for (const feature of features) {
          const featureId = feature.id;
          const featureName = feature.name;
          const userFlag = this.userFlagsMap.get(featureId);
          const firmFlag = this.firmFlagsMap.get(featureId);

          // Determine feature flag status based on priority
          let isEnabled: boolean;
          if (userFlag !== undefined || firmFlag !== undefined) {
            isEnabled =
              userFlag?.is_enabled || false || firmFlag?.is_enabled || false;
          } else {
            isEnabled = this.getFeatureDefaultState(featureId);
          }

          let trialEndDate: Date | null = null;

          // Handle trial dates - take the later trial end date if both exist
          if (userFlag?.trial_end_date || firmFlag?.trial_end_date) {
            const userDate = userFlag?.trial_end_date
              ? new Date(userFlag.trial_end_date)
              : null;
            const firmDate = firmFlag?.trial_end_date
              ? new Date(firmFlag.trial_end_date)
              : null;

            if (userDate && firmDate) {
              trialEndDate = userDate > firmDate ? userDate : firmDate;
            } else {
              trialEndDate = userDate || firmDate;
            }
          } else {
            trialEndDate = null;
          }

          // Handle trial expiration
          if (trialEndDate && trialEndDate < new Date()) {
            isEnabled = false;
            console.log(`Trial expired. isEnabled set to false.`);
          }

          // Cache the result
          this.featureFlagsCache.set(featureName, {
            isEnabled: !!isEnabled,
            trialEndDate,
          });
        }

        // Set up listeners
        await this.setupFeatureFlagListeners();

        // Notify subscribers after initial load
        this.notifyFeatureFlagsChanged();
      }
    } catch (error) {
      console.error("Error loading feature flags:", error);
      throw error;
    } finally {
      this.setIsLoading(false);
    }
  }

  private async setupFeatureFlagListeners(): Promise<void> {
    const identifier = this.getIdentifier();
    const subscriptionPromises: Promise<void>[] = [];

    // Listener for user_feature_flag changes
    if (identifier.userId) {
      const userFlagListener = this.supabase
        .channel(`user_feature_flags:${identifier.userId}`)
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "user_feature_flag",
            filter: `user_id=eq.${identifier.userId}`,
          },
          (payload: RealtimePostgresChangesPayload<FeatureFlag>) => {
            if (payload.new && "feature_id" in payload.new) {
              const featureId = payload.new.feature_id;
              const featureName = this.featureIdToName.get(featureId);
              if (featureName) {
                this.updateFeatureFlagFromPayload(
                  featureName,
                  payload.new,
                  "user",
                );
              }
            } else {
              console.warn("Received invalid payload:", payload.new);
            }
          },
        );

      subscriptionPromises.push(this.subscribeToChannel(userFlagListener));
      this.listeners.push(userFlagListener);
    }

    // Listener for firm_feature_flag changes
    if (identifier.firmId) {
      const firmFlagListener = this.supabase
        .channel(`firm_feature_flags:${identifier.firmId}`)
        .on(
          "postgres_changes",
          {
            event: "*",
            schema: "public",
            table: "firm_feature_flag",
            filter: `firm_id=eq.${identifier.firmId}`,
          },
          (payload: RealtimePostgresChangesPayload<FeatureFlag>) => {
            if (payload.new && "feature_id" in payload.new) {
              const featureId = payload.new.feature_id;
              const featureName = this.featureIdToName.get(featureId);
              if (featureName) {
                this.updateFeatureFlagFromPayload(
                  featureName,
                  payload.new,
                  "firm",
                );
              }
            } else {
              console.warn("Received invalid payload:", payload.new);
            }
          },
        );

      subscriptionPromises.push(this.subscribeToChannel(firmFlagListener));
      this.listeners.push(firmFlagListener);
    }

    // Listener for feature default state changes
    const featureListener = this.supabase.channel("feature_changes").on(
      "postgres_changes",
      {
        event: "*",
        schema: "public",
        table: "feature",
      },
      (payload: RealtimePostgresChangesPayload<Feature>) => {
        if (payload.new && "id" in payload.new && "name" in payload.new) {
          const newFeature = payload.new as Feature;
          const featureName = newFeature.name;
          const featureId = newFeature.id;

          // Update the feature in the maps
          this.featureIdToName.set(featureId, featureName);
          this.featureDefaultsMap.set(featureId, newFeature.default_state);

          const userFlag = this.userFlagsMap.get(featureId);
          const firmFlag = this.firmFlagsMap.get(featureId);

          // Determine feature flag status based on priority
          let isEnabled: boolean;
          if (userFlag !== undefined || firmFlag !== undefined) {
            isEnabled =
              userFlag?.is_enabled || false || firmFlag?.is_enabled || false;
          } else {
            isEnabled = this.getFeatureDefaultState(featureId);
          }

          let trialEndDate: Date | null = null;

          // Handle trial dates - take the later trial end date if both exist
          if (userFlag?.trial_end_date || firmFlag?.trial_end_date) {
            const userDate = userFlag?.trial_end_date
              ? new Date(userFlag.trial_end_date)
              : null;
            const firmDate = firmFlag?.trial_end_date
              ? new Date(firmFlag.trial_end_date)
              : null;

            if (userDate && firmDate) {
              trialEndDate = userDate > firmDate ? userDate : firmDate;
            } else {
              trialEndDate = userDate || firmDate;
            }
          } else {
            trialEndDate = null;
          }

          // Handle trial expiration
          if (trialEndDate && trialEndDate < new Date()) {
            isEnabled = false;
            console.log(`Trial expired. isEnabled set to false.`);
          }

          // Cache the result
          this.featureFlagsCache.set(featureName, {
            isEnabled: !!isEnabled,
            trialEndDate,
          });

          // Notify subscribers
          this.notifyFeatureFlagsChanged();
        } else {
          console.warn("Received invalid payload:", payload.new);
        }
      },
    );

    subscriptionPromises.push(this.subscribeToChannel(featureListener));
    this.listeners.push(featureListener);

    // Wait for all subscriptions to be confirmed
    await Promise.all(subscriptionPromises);
  }

  private updateFeatureFlagFromPayload(
    featureName: string,
    newFlagData: FeatureFlag,
    source: "user" | "firm",
  ) {
    if (newFlagData && "is_enabled" in newFlagData) {
      const featureId = newFlagData.feature_id;
      const trialEndDate = newFlagData.trial_end_date
        ? new Date(newFlagData.trial_end_date)
        : null;

      // Update the appropriate map
      if (source === "user") {
        if (newFlagData.is_enabled || trialEndDate) {
          this.userFlagsMap.set(featureId, newFlagData);
        } else {
          this.userFlagsMap.delete(featureId);
        }
      } else if (source === "firm") {
        if (newFlagData.is_enabled || trialEndDate) {
          this.firmFlagsMap.set(featureId, newFlagData);
        } else {
          this.firmFlagsMap.delete(featureId);
        }
      }

      // Recompute isEnabled based on priority
      let isEnabled: boolean;
      if (
        this.userFlagsMap.has(featureId) ||
        this.firmFlagsMap.has(featureId)
      ) {
        const userFlag = this.userFlagsMap.get(featureId);
        const firmFlag = this.firmFlagsMap.get(featureId);
        isEnabled =
          userFlag?.is_enabled || false || firmFlag?.is_enabled || false;
      } else {
        isEnabled = this.getFeatureDefaultState(featureId);
      }

      let combinedTrialEndDate: Date | null = null;

      // Handle trial dates - take the later trial end date if both exist
      const userFlag = this.userFlagsMap.get(featureId);
      const firmFlag = this.firmFlagsMap.get(featureId);

      if (userFlag?.trial_end_date || firmFlag?.trial_end_date) {
        const userDate = userFlag?.trial_end_date
          ? new Date(userFlag.trial_end_date)
          : null;
        const firmDate = firmFlag?.trial_end_date
          ? new Date(firmFlag.trial_end_date)
          : null;

        if (userDate && firmDate) {
          combinedTrialEndDate = userDate > firmDate ? userDate : firmDate;
        } else {
          combinedTrialEndDate = userDate || firmDate;
        }
      } else {
        combinedTrialEndDate = null;
      }

      // Handle trial expiration
      if (combinedTrialEndDate && combinedTrialEndDate < new Date()) {
        isEnabled = false;
        console.log(`Trial expired. isEnabled set to false.`);
      }

      // Cache the result
      this.featureFlagsCache.set(featureName, {
        isEnabled: !!isEnabled,
        trialEndDate: combinedTrialEndDate,
      });

      // Notify subscribers
      this.notifyFeatureFlagsChanged();
    } else {
      console.warn("Received invalid payload:", newFlagData);
    }
  }

  // Helper method to get the default state of a feature
  private getFeatureDefaultState(featureId: number): boolean {
    return this.featureDefaultsMap.get(featureId) ?? false;
  }

  getAllFeatureFlags(): Map<string, FeatureFlagData> {
    return this.featureFlagsCache;
  }

  subscribeToFeatureFlagChanges(
    callback: (featureFlags: Map<string, FeatureFlagData>) => void,
  ): () => void {
    this.featureFlagsChangeCallbacks.add(callback);
    return () => {
      this.featureFlagsChangeCallbacks.delete(callback);
    };
  }

  private notifyFeatureFlagsChanged() {
    for (const callback of this.featureFlagsChangeCallbacks) {
      callback(new Map(this.featureFlagsCache)); // Pass a copy of the cache
    }
  }

  getIsLoading(): boolean {
    return this.isLoading;
  }

  getUserId(): string | undefined {
    return this.userId;
  }

  getFirmId(): string | null | undefined {
    return this.firmId;
  }

  subscribeToIsLoadingChanges(
    callback: (isLoading: boolean) => void,
  ): () => void {
    this.isLoadingChangeCallbacks.add(callback);
    return () => {
      this.isLoadingChangeCallbacks.delete(callback);
    };
  }

  private notifyIsLoadingChanged(newState: boolean) {
    for (const callback of this.isLoadingChangeCallbacks) {
      callback(newState);
    }
  }

  private setIsLoading(newState: boolean) {
    this.isLoading = newState;
    this.notifyIsLoadingChanged(newState);
  }

  protected getIdentifier(options?: { userId?: string; firmId?: string }) {
    return {
      userId: options?.userId ?? this.userId,
      firmId: options?.firmId ?? this.firmId,
    };
  }

  async cleanup() {
    // Unsubscribe from all listeners
    for (const listener of this.listeners) {
      await this.supabase.removeChannel(listener);
    }
    this.listeners = [];
  }
}
