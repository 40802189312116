import InitialsAvatar from "@/components/InitialsAvatar";
import { useStatusBanner } from "@/components/StatusBannerProvider";
import SuspenseOutlet from "@/components/SuspenseOutlet";
import NoFirm from "@/pages/auth/NoFirm";
import {
  appVersionAtom,
  firmIdAtom,
  supabaseClientAtom,
  supabaseSessionAtom,
  supabaseUserAtom,
} from "@/supabase";
import { useIsFeatureEnabled } from "@precedent/shared-util";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import { useEffect, useRef, useState } from "react";
import { Menu, Navbar } from "react-daisyui";
import { MdExpandMore } from "react-icons/md";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const MainLayout: React.FC = () => {
  const supabaseUser = useAtomValue(supabaseUserAtom);
  const firmId = useAtomValue(firmIdAtom);
  const appVersion = useAtomValue(appVersionAtom);
  const { banner } = useStatusBanner();

  const [showVersion, setShowVersion] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div className="flex h-screen flex-col">
      <Navbar
        className={twMerge(
          "fixed z-20 h-[70px] bg-base-100",
          "border-b border-b-base-200",
          banner ? "top-16" : "top-0",
        )}
      >
        <Navbar.Start>
          <Link className="ml-2 text-2xl font-bold" to="/">
            {import.meta.env.VITE_APP_NAME || "Briefly"}
          </Link>
          <div
            className={classNames(
              "ml-4 cursor-default text-gray-600 opacity-0",
              "transition-opacity duration-100 ease-in",
              "text-sm",
              showVersion ? "opacity-100" : "opacity-0",
            )}
            onClick={() => {
              setShowVersion((x) => !x);
            }}
          >
            {appVersion}
          </div>
        </Navbar.Start>

        {firmId && (
          <Navbar.Center className="hidden sm:flex">
            <Menu horizontal className="gap-2 px-1">
              {useIsFeatureEnabled("chat-module") && (
                <Menu.Item>
                  <Link
                    className="btn btn-ghost btn-sm rounded py-1.5 text-sm font-normal hover:bg-gray-100"
                    to="/chat"
                  >
                    Chat
                  </Link>
                </Menu.Item>
              )}
              {useIsFeatureEnabled("questionnaires-module") && (
                <Menu.Item>
                  <Link
                    className="btn btn-ghost btn-sm rounded py-1.5 text-sm font-normal hover:bg-gray-100"
                    to="/questionnaires"
                  >
                    Questionnaires
                  </Link>
                </Menu.Item>
              )}
              {useIsFeatureEnabled("directory-submissions-module") && (
                <Menu.Item>
                  <Link
                    className="btn btn-ghost btn-sm rounded py-1.5 text-sm font-normal hover:bg-gray-100"
                    to="/directory-submissions"
                  >
                    Directory Submissions
                  </Link>
                </Menu.Item>
              )}
              {useIsFeatureEnabled("knowledge-graph") && (
                <Menu.Item>
                  <Link
                    className="btn btn-ghost btn-sm rounded py-1.5 text-sm font-normal hover:bg-gray-100"
                    to="/knowledge"
                  >
                    Knowledge
                  </Link>
                </Menu.Item>
              )}
            </Menu>
          </Navbar.Center>
        )}

        <Navbar.End className="flex gap-2 pr-3">
          <DropdownMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />

          <InitialsAvatar
            user={supabaseUser}
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          />
        </Navbar.End>
      </Navbar>

      <div
        className={twMerge(
          "mt-[70px]",
          banner ? "mt-[134px]" : "mt-[70px]", // 134px = banner (64px) + navbar (70px)
        )}
        style={{
          height: `calc(100% - ${banner ? "134px" : "70px"})`,
        }}
      >
        <main className="flex h-full max-w-full flex-1 flex-col">
          {(() => {
            if (firmId !== "loading" && !!firmId) {
              return <SuspenseOutlet />;
            } else {
              return <NoFirm />;
            }
          })()}
        </main>
      </div>
    </div>
  );
};

export default MainLayout;

const DropdownMenu = ({
  menuOpen,
  setMenuOpen,
}: {
  menuOpen: boolean;
  setMenuOpen: (newValue: boolean) => void;
}) => {
  const supabase = useAtomValue(supabaseClientAtom);
  const session = useAtomValue(supabaseSessionAtom);
  const firmId = useAtomValue(firmIdAtom);

  const buttonRef = useRef<any>(null);
  const dropdownRef = useRef<any>(null);

  const signOut = () => {
    void (async () => {
      // Clear chat messages from localStorage
      for (const key in localStorage) {
        if (key.startsWith("chat_message_")) {
          localStorage.removeItem(key);
        }
      }

      if (supabase) {
        const { error } = await supabase.auth.signOut();
        if (error) {
          console.error(error);
        }
      }
    })();
  };

  const handleClickOutside = (event: any) => {
    if (
      buttonRef.current &&
      dropdownRef.current &&
      !buttonRef.current.contains(event.target) &&
      !dropdownRef.current.contains(event.target)
    ) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setMenuOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, []);

  return (
    <div className="dropdown relative">
      <div
        tabIndex={0}
        role="button"
        className="btn btn-ghost btn-sm rounded py-1.5 text-sm font-normal hover:bg-gray-100"
        onClick={() => {
          setMenuOpen(!menuOpen);
        }}
        ref={buttonRef}
      >
        Configuration
        <MdExpandMore
          className={classNames(
            "ease transform transition transition-transform duration-200",
            menuOpen && "rotate-180",
          )}
        />
      </div>
      {menuOpen && (
        <ul
          tabIndex={0}
          className="menu absolute -right-2 top-10 z-[1] w-52 rounded-box rounded-md border border-gray-200 bg-base-100 p-0 shadow"
          ref={dropdownRef}
        >
          {session && firmId ? (
            <>
              <li>
                <a
                  className="rounded-none rounded-t-md hover:bg-gray-100"
                  href="/documents"
                >
                  Upload documents
                </a>
              </li>
              <li>
                <a className="rounded-none hover:bg-gray-100" href="/urls">
                  Import a website
                </a>
              </li>
              <li>
                <a className="rounded-none hover:bg-gray-100" href="/settings">
                  User Settings
                </a>
              </li>
              <li>
                <a
                  className="rounded-none hover:bg-gray-100"
                  href="/firm-settings"
                >
                  Firm Settings
                </a>
              </li>
              <li>
                <a
                  className="rounded-none rounded-b-md hover:bg-gray-100"
                  onClick={signOut}
                >
                  Sign out
                </a>
              </li>
            </>
          ) : session ? (
            <li>
              <a className="rounded-md hover:bg-gray-100" onClick={signOut}>
                Sign out
              </a>
            </li>
          ) : (
            <>
              <li>
                <a
                  className="rounded-none rounded-t-md hover:bg-gray-100"
                  href="/auth/sign-in"
                >
                  Sign in <span className="badge">New</span>
                </a>
              </li>
              <li>
                <a
                  className="rounded-none rounded-b-md hover:bg-gray-100"
                  href="/auth/sign-up"
                >
                  Sign up <span className="badge">New</span>
                </a>
              </li>
            </>
          )}
        </ul>
      )}
    </div>
  );
};
