import {
  accountTypeAtom,
  firm2faRequiredAtom,
  firmIdAtom,
  firmNameAtom,
  supabaseClientAtom,
  supabaseSessionAtom,
  supabaseUserAtom,
} from "@/supabase";
import { type User } from "@precedent/db-types/src/schema";
import {
  type SupabaseClient,
  type Session as SupabaseSession,
} from "@supabase/supabase-js";
import { useAtomValue } from "jotai";

type AuthContext = {
  session: SupabaseSession | null;
  supabase: SupabaseClient | undefined;
  firmId: string | undefined;
  userId: string | undefined;
  user: User | undefined;
  firmName: string | undefined;
  isLoading: boolean;
  accountType: string | null | undefined;
  firm2faRequired: boolean | undefined;
};

export function useAuth(): AuthContext {
  const session = useAtomValue(supabaseSessionAtom);
  const supabase = useAtomValue(supabaseClientAtom);
  const supabaseUser = useAtomValue(supabaseUserAtom);
  const firmId = useAtomValue(firmIdAtom);
  const firmName = useAtomValue(firmNameAtom);
  const firm2faRequired = useAtomValue(firm2faRequiredAtom);
  const accountType = useAtomValue(accountTypeAtom);

  const userId = (() => {
    if (session && session !== "loading") {
      return session.user.id;
    }
  })();

  return {
    session: session && session !== "loading" ? session : null,
    supabase,
    firmId,
    userId,
    user: supabaseUser,
    firmName,
    firm2faRequired,
    isLoading: session === "loading" || supabase === null,
    accountType,
  };
}
