import {
  type SIDE_PANELS_AVAILABLE,
  SubmissionsContext,
} from "@/components/DirectorySubmissions/Form/SubmissionsProvider";
import type {
  DirectorySubmissionFull,
  SubmissionFormData,
} from "@/components/DirectorySubmissions/Form/types";
import type React from "react";
import { useState } from "react";

const AttorneySubmissionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // We supply minimal (dummy) values so that components using useSubmissions don't error.
  const [dummyFormData, setDummyFormData] = useState<SubmissionFormData>({});
  const dummyContext = {
    isSaving: false,
    setIsSaving: () => {},
    isLocked: false,
    submissionData: null as DirectorySubmissionFull | null,
    formData: dummyFormData,
    setFormData: setDummyFormData,
    initialFormData: null,
    error: false,
    isFormLocked: false,
    showLockWarning: () => {},
    restoreRevision: () => {},
    updateLockState: (isLocked: boolean) => {},
    sidePanelOpen: null as SIDE_PANELS_AVAILABLE | null,
    openSidePanel: (panelName: SIDE_PANELS_AVAILABLE, questionId: string) => {},
    closeSidePanel: () => {},
    submissionComments: {},
    submissionRevisions: {},
    activeCommentQuestion: null,
    setActiveCommentQuestion: (questionId: string | null) => {},
    saveField: () => Promise.resolve(),
    pendingSaves: new Set<string>(),
    approvedQuestions: new Set<string>(),
    isQuestionApproved: (questionId: string) => false,
  };

  return (
    <SubmissionsContext.Provider value={dummyContext}>
      {children}
    </SubmissionsContext.Provider>
  );
};

export default AttorneySubmissionsProvider;
