import AttorneySubmissionsProvider from "@/components/DirectorySubmissions/AttorneySubmissionsProvider";
import Spinner from "@/components/Spinner";
import { useAuth } from "@/contexts/AuthHook";
import AttorneyLayout from "@/layouts/AttorneyLayout";
import AuthLayout from "@/layouts/AuthLayout";
import MainLayout from "@/layouts/MainLayout";
import MinimalLayout from "@/layouts/MinimalLayout";
import ProtectedLayout from "@/layouts/ProtectedLayout";
import Page404 from "@/pages/Page404";
import { useFeatureFlags, useIsFeatureEnabled } from "@precedent/shared-util";
import { lazy } from "react";
import { Navigate, type RouteObject } from "react-router-dom";

// Lazy load components
const SettingsPage = lazy(() => import("@/pages/Settings"));
const FirmSettingsPage = lazy(() => import("@/pages/FirmSettings"));
const ChatPage = lazy(() => import("@/pages/Chat"));
const DocumentUpload = lazy(() => import("@/pages/DocumentUpload"));
const DocumentPreview = lazy(() => import("@/pages/DocumentPreview"));
const IntappExport = lazy(() => import("@/pages/IntappExport"));
const AttorneyMattersPage = lazy(() => import("@/pages/AttorneyMattersPage"));
const AttorneyReviewList = lazy(() => import("@/pages/AttorneyReviewList"));
const DirectorySubmissions = lazy(() => import("@/pages/DirectorySubmissions"));
const KnowledgeGraph = lazy(() => import("@/pages/KnowledgeGraph"));
const SubmissionDetail = lazy(
  () => import("@/pages/DirectorySubmissions/SubmissionDetail"),
);
const SubmissionReviewsList = lazy(
  () => import("@/pages/SubmissionReviewsList"),
);
const Questionnaires = lazy(() => import("@/pages/Questionnaires"));
const QuestionnaireDetail = lazy(
  () => import("@/pages/Questionnaires/QuestionnaireDetail"),
);
const Urls = lazy(() => import("@/pages/Urls"));
const Recovery = lazy(() => import("@/pages/auth/Recovery"));
const Reset = lazy(() => import("@/pages/auth/Reset"));
const MagicLink = lazy(() => import("@/pages/auth/MagicLink"));
const SubmissionReviewMagicLink = lazy(
  () => import("@/pages/auth/SignInForSubmissionReview"),
);
const SignIn = lazy(() => import("@/pages/auth/SignIn"));
const SignUp = lazy(() => import("@/pages/auth/SignUp"));
const SignInWithMagicLink = lazy(
  () => import("@/pages/auth/SignInWithMagicLink"),
);
const AttorneyReviewPage = lazy(() => import("@/pages/AttorneyReviewPage"));
const AttorneySubmissionConfirmationPage = lazy(
  () => import("@/pages/AttorneySubmissionConfirmationPage"),
);

// Feature protection HOC
const withFeatureProtection = (
  WrappedComponent: React.ComponentType<any>,
  featureFlag: string,
): React.ReactElement => {
  const FeatureProtectedComponent: React.FC = (props) => {
    const isFeatureEnabled = useIsFeatureEnabled(featureFlag);
    return isFeatureEnabled ? <WrappedComponent {...props} /> : <Page404 />;
  };
  return <FeatureProtectedComponent />;
};

// Root Redirect Component
const RootRedirect = () => {
  const chatEnabled = useIsFeatureEnabled("chat-module");
  const questionnairesEnabled = useIsFeatureEnabled("questionnaires-module");
  const directorySubmissionsEnabled = useIsFeatureEnabled(
    "directory-submissions-module",
  );

  const { userId: authUserId } = useAuth();
  const { isLoading, userId: featureFlagUserId } = useFeatureFlags();

  // Check if feature flags are still loading
  if (
    isLoading ||
    chatEnabled === null ||
    questionnairesEnabled === null ||
    directorySubmissionsEnabled === null ||
    authUserId !== featureFlagUserId
  ) {
    return <Spinner />;
  }

  // Proceed with routing decision based on loaded feature flags
  if (chatEnabled) {
    return <Navigate to="/chat" replace />;
  }

  if (questionnairesEnabled) {
    return <Navigate to="/questionnaires" replace />;
  }

  if (directorySubmissionsEnabled) {
    return <Navigate to="/directory-submissions" replace />;
  }

  return <Navigate to="/firm-settings" replace />;
};

const routes: RouteObject[] = [
  {
    path: "/",
    element: <ProtectedLayout />,
    children: [
      {
        element: <MainLayout />, // Layout for main protected routes
        children: [
          { index: true, element: <RootRedirect /> },
          {
            path: "chat",
            children: [
              {
                index: true,
                element: withFeatureProtection(ChatPage, "chat-module"),
              },
              {
                path: ":chatId",
                element: withFeatureProtection(ChatPage, "chat-module"),
              },
            ],
          },
          {
            path: "urls",
            element: <Urls />,
          },
          {
            path: "settings",
            element: <SettingsPage />,
          },
          {
            path: "firm-settings",
            element: <FirmSettingsPage />,
          },
          {
            path: "questionnaires",
            children: [
              {
                index: true,
                element: withFeatureProtection(
                  Questionnaires,
                  "questionnaires-module",
                ),
              },
              {
                path: ":documentId",
                element: withFeatureProtection(
                  QuestionnaireDetail,
                  "questionnaires-module",
                ),
              },
            ],
          },
          {
            path: "documents",
            children: [
              { index: true, element: <DocumentUpload /> },
              { path: "e/:embeddingId", element: <DocumentPreview /> },
              { path: "*", element: <DocumentUpload /> },
            ],
          },
          {
            path: "directory-submissions",
            children: [
              {
                index: true,
                element: withFeatureProtection(
                  DirectorySubmissions,
                  "directory-submissions-module",
                ),
              },
              {
                path: ":submissionId",
                element: withFeatureProtection(
                  SubmissionDetail,
                  "directory-submissions-module",
                ),
              },
              {
                path: ":submissionId/reviews",
                element: withFeatureProtection(
                  SubmissionReviewsList,
                  "directory-submissions-module",
                ),
              },
            ],
          },
          {
            path: "knowledge",
            children: [
              {
                index: true,
                element: withFeatureProtection(
                  KnowledgeGraph,
                  "knowledge-graph",
                ),
              },
              {
                path: "type/:entityTypeId",
                element: withFeatureProtection(
                  KnowledgeGraph,
                  "knowledge-graph",
                ),
                children: [
                  {
                    path: "entity/:entityId",
                    element: withFeatureProtection(
                      KnowledgeGraph,
                      "knowledge-graph",
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <MinimalLayout />, // Different layout for specific route
        children: [{ path: "intapp-export", element: <IntappExport /> }],
      },
    ],
  },
  {
    path: "attorney/directory-submissions",
    children: [
      {
        path: "review-submissions",
        element: <AttorneyLayout />, // these pages require login
        children: [
          {
            index: true,
            element: <AttorneyReviewList />,
          },
          {
            path: ":reviewId",
            element: <AttorneyReviewPage />,
          },
        ],
      },
      {
        path: "submit-matters",
        element: <MinimalLayout />, // these pages do not require login
        children: [
          {
            path: ":attorneyLinkId",
            element: (
              <AttorneySubmissionsProvider>
                <AttorneyMattersPage />
              </AttorneySubmissionsProvider>
            ),
          },
          {
            path: "confirmation",
            element: <AttorneySubmissionConfirmationPage />,
          },
        ],
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      { path: "sign-in", element: <SignIn /> },
      { path: "sign-up", element: <SignUp /> },
      { path: "recovery", element: <Recovery /> },
      { path: "set-password", element: <Reset /> },
      { path: "sign-in-with-magic-link", element: <SignInWithMagicLink /> },
      { path: "magic-link", element: <MagicLink /> },
      { path: "submission-review", element: <SubmissionReviewMagicLink /> },
    ],
  },
  {
    path: "*",
    element: <Page404 />,
  },
];

export default routes;
