import { handleResponse } from "@precedent/shared-util";
import {
  type SupabaseClient,
  type Session as SupabaseSession,
} from "@supabase/supabase-js";
import { QueryClient, useMutation } from "@tanstack/react-query";
import invariant from "tiny-invariant";
import wretch from "wretch";

invariant(
  import.meta.env.VITE_AZURE_FUNCTIONS_URL,
  "VITE_AZURE_FUNCTIONS_URL not provided",
);

invariant(import.meta.env.VITE_SCRAPE_URL, "VITE_SCRAPE_URL not provided");
invariant(import.meta.env.VITE_EMBS_URL, "VITE_EMBS_URL not provided");
invariant(import.meta.env.VITE_BEBE_URL, "VITE_BEBE_URL not provided");

const {
  VITE_AZURE_FUNCTIONS_URL,
  VITE_SCRAPE_URL,
  VITE_EMBS_URL,
  VITE_BEBE_URL,
} = import.meta.env;

export const azureFunctionsServer = wretch(VITE_AZURE_FUNCTIONS_URL);
export const scrapeServer = wretch(VITE_SCRAPE_URL);
export const embsServer = wretch(VITE_EMBS_URL);
export const bebeServer = wretch(VITE_BEBE_URL);

export const queryClient = new QueryClient();

export const queryKeys = {
  urls: (firmId: string, parentUrl: string) => [
    "urls_by_parent",
    firmId,
    parentUrl,
  ],
  ourUrls: (firmId: string) => ["our_urls", firmId],
  urlsStatuses: () => ["urls_statuses"],
  rfps: (firmId: string) => ["rfps", firmId],
  rfp: (documentId: string) => ["rfp", documentId],
  responseQuestions: (fileId: string) => ["response_questions", fileId],
  embeddingFiles: (firmId: string) => ["embeddingFiles", firmId],
  responseFile: (documentId: string) => ["responseFile", documentId],
  embedding: (embeddingId: string) => ["embedding", embeddingId],
  files: (firmId: string) => ["files", firmId],
  chatFolders: (userId: string) => ["chat_folders", userId],
  chats: (userId: string) => ["chats", userId],
  chat: (chatId: string) => ["chat", chatId],
  settings: (type: string, userId: string) => ["settings", type, userId],
  lawFirm: (userId: string) => ["lawFirm", userId],
  firmUsers: (firmId: string) => ["firmUsers", firmId],
  directorySubmissions: (
    firmId: string,
    currentPage: number,
    pageSize: number,
    search: string,
    selectedYears: number[],
    selectedRegions: string[],
    selectedStatus: string[],
    selectedAssignees: string[],
    sortField: string,
    sortOrder: string,
  ) => [
    "directory_submissions_table",
    firmId,
    currentPage,
    pageSize,
    search,
    selectedYears,
    selectedRegions,
    selectedStatus,
    selectedAssignees,
    sortField,
    sortOrder,
  ],
  directorySubmission: (submissionId: string) => [
    "directory_submission",
    submissionId,
  ],
  directorySubmissionComments: (submissionId: string) => [
    "directory_submission_comments",
    submissionId,
  ],
  directorySubmissionRevisions: (submissionId: string) => [
    "directory_submission_revisions",
    submissionId,
  ],
  previousSubmissions: (submissionId: string) => [
    "previous_submissions",
    submissionId,
  ],
  chambersImportStatus: (fileId: string) => ["chambers_import_status", fileId],
  intappCredentials: (firmId: string) => ["intapp_credentials", firmId],
  attorneyReviewLinks: (userEmail: string) => [
    "attorney_review_links",
    userEmail,
  ],
  submissionReviewLinks: (submissionId: string) => [
    "submission_review_links",
    submissionId,
  ],
};

export const useExportToL500DocxMutation = () => {
  return useMutation({
    mutationFn: async ({
      supabase,
      submissionId,
      userId,
    }: {
      supabase: SupabaseClient;
      session: SupabaseSession | null;
      submissionId: string;
      userId: string;
    }): Promise<{ id: string }> =>
      await supabase
        .from("ai_task")
        .insert({
          type: "CHAMBERS_SUBMISSION_EXPORT",
          status: "GENERATING_L500",
          input: submissionId,
          created_by: userId,
          processing_started_at: "now()",
        })
        .select("id")
        .single()
        .then(handleResponse<{ id: string }>),
    onSuccess: async ({ id: aiTaskId }, args) => {
      const { session, submissionId } = args;
      if (session) {
        await azureFunctionsServer
          .auth(`Bearer ${session.access_token}`)
          .url("/export-l500-submission")
          .post({ aiTaskId, submissionId })
          .text();
      }
    },
  });
};
