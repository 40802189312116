import { SubmissionsProvider } from "@/components/DirectorySubmissions/Form/SubmissionsProvider";
import Spinner from "@/components/Spinner";
import { useAuth } from "@/contexts/AuthHook";
import type React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedLayout: React.FC = () => {
  const { session, accountType, isLoading, user } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <Spinner />;
  }

  if (!session || !user) {
    return <Navigate to="/auth/sign-in" replace state={{ from: location }} />;
  }

  // If the user is a review account and trying to access a non-attorney page, redirect them
  if (accountType === "attorney") {
    return (
      <Navigate
        to="/attorney/directory-submissions/review-submissions/"
        replace
      />
    );
  }

  return (
    <SubmissionsProvider>
      <Outlet />
    </SubmissionsProvider>
  );
};

export default ProtectedLayout;
