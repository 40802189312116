import StatusBanner from "@/components/StatusBanner";
import { StatusBannerProvider } from "@/components/StatusBannerProvider";
import routes from "@/router/routes";
import {
  accountTypeAtom,
  appVersionAtom,
  firm2faRequiredAtom,
  firmIdAtom,
  firmNameAtom,
  supabaseClientAtom,
  supabaseSessionAtom,
  supabaseUrlAtom,
  supabaseUserAtom,
} from "@/supabase";
import HyperDX from "@hyperdx/browser";
import { type User } from "@precedent/db-types/src/schema";
import {
  FeatureFlagProvider,
  SupabaseFeatureFlagService,
} from "@precedent/shared-util";
import {
  type RealtimeChannel,
  type SupabaseClient,
  createClient,
} from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";
import { useAtom, useSetAtom } from "jotai";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import invariant from "tiny-invariant";

if (import.meta.env.PROD) {
  invariant(
    import.meta.env.VITE_HYPERDX_API_KEY,
    "HyperDX API key was not provided",
  );

  HyperDX.init({
    apiKey: import.meta.env.VITE_HYPERDX_API_KEY,
    service: "ask-app",
    consoleCapture: true,
    advancedNetworkCapture: false,
    maskAllInputs: true,
    maskAllText: true,
  });
}

invariant(
  import.meta.env.VITE_SUPABASE_PUBLIC_KEY,
  "VITE_SUPABASE_PUBLIC_KEY not provided",
);
invariant(import.meta.env.VITE_SUPABASE_URL, "VITE_SUPABASE_URL not provided");

const { VITE_SUPABASE_PUBLIC_KEY, VITE_SUPABASE_URL, VITE_APP_VERSION } =
  import.meta.env;

const appVersion = VITE_APP_VERSION ?? "000000000";

const router = createBrowserRouter(routes);

const App = () => {
  const [supabase, setSupabaseClient] = useAtom(supabaseClientAtom);
  const [user, setUser] = useAtom(supabaseUserAtom);
  const setSession = useSetAtom(supabaseSessionAtom);
  const setFirmId = useSetAtom(firmIdAtom);
  const setAccountType = useSetAtom(accountTypeAtom);
  const setSupabaseUrl = useSetAtom(supabaseUrlAtom);
  const [firmName, setFirmName] = useAtom(firmNameAtom);
  const [firm2faRequired, setFirm2faRequired] = useAtom(firm2faRequiredAtom);
  const setAppVersion = useSetAtom(appVersionAtom);
  const { enqueueSnackbar } = useSnackbar();

  const [featureFlagService, setFeatureFlagService] =
    useState<SupabaseFeatureFlagService | null>(null);

  const supabaseClientResult = useQuery({
    queryKey: ["supabase_client"],
    queryFn: () => {
      return new Promise<SupabaseClient>((resolve) => {
        const client = createClient(
          VITE_SUPABASE_URL,
          VITE_SUPABASE_PUBLIC_KEY,
        );
        resolve(client);
      });
    },
    enabled: !supabase,
    staleTime: Infinity,
    gcTime: Infinity,
  });

  useEffect(() => {
    if (supabaseClientResult.data) {
      setSupabaseClient(supabaseClientResult.data);
      setSupabaseUrl(VITE_SUPABASE_URL);
    }
  }, [supabaseClientResult]);

  useEffect(() => {
    setAppVersion(appVersion);

    if (!supabase) {
      return;
    }

    const fetchUserData = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (session) {
        const { data: user, error } = await supabase
          .from("user")
          .select("*")
          .eq("id", session.user.id)
          .single();

        if (error) {
          throw new Error(error.message);
        }

        if (user) {
          setUser(user);
          setFirmId(user.firm_id || undefined);
          setAccountType(user.account_type || undefined);

          if (user.id) {
            HyperDX.setGlobalAttributes({
              userId: user.id,
            });

            if (user.firm_id) {
              HyperDX.setGlobalAttributes({
                firmId: user.firm_id,
              });
            }
          }

          // Only set session after firmId is set
          setSession(session);

          if (user.firm_id) {
            const { data: firm, error: firmError } = await supabase
              .from("law_firm")
              .select("*")
              .eq("id", user.firm_id)
              .single();

            if (firmError) {
              console.error(firmError);
            }

            if (firm) {
              setFirmName(firm.name);
              setFirm2faRequired(firm.is_2fa_required);
            } else {
              setFirmName(undefined);
            }
          } else {
            setFirmName(undefined);
          }
        } else {
          await supabase.auth.signOut();
        }
      }
    };

    void fetchUserData();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        setSession(session);
        void fetchUserData();
      } else if (event === "PASSWORD_RECOVERY") {
        window.location.href = "/auth/set-password";
      } else if (event === "SIGNED_OUT") {
        setSession(null);
        setUser(undefined);
        setFirmId(undefined);
        setAccountType(undefined);
        setFirmName(undefined);

        window.location.href = "/auth/sign-in";
      } else {
        if (session) {
          void fetchUserData(); // Call fetchUserData to handle the new session
        } else {
          // Handle case when there's no session (user logged out)
          setSession(null);
          setUser(undefined);
          setFirmId(undefined);
          setAccountType(undefined);
          setFirmName(undefined);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [supabase]);

  useEffect(() => {
    let subscription: RealtimeChannel;

    const subscribeToUserChanges = () => {
      if (supabase && user) {
        subscription = supabase
          .channel(`user-changes-${user.id}`)
          .on(
            "postgres_changes",
            {
              event: "UPDATE",
              schema: "public",
              table: "user",
              filter: `id=eq.${user.id}`,
            },
            (payload: { new: User }) => {
              enqueueSnackbar("Profile updated successfully", {
                variant: "success",
              });

              const newUser = payload.new;
              setUser(newUser);
              setFirmId(newUser.firm_id ?? undefined);
              setAccountType(
                newUser.account_type as "attorney" | "standard" | undefined,
              );

              if (featureFlagService) {
                void featureFlagService.handleExternalUpdate({
                  userId: newUser.id,
                  firmId: newUser.firm_id,
                });
              }
            },
          )
          .subscribe();
      }
    };

    subscribeToUserChanges();

    return () => {
      if (subscription && supabase) {
        void supabase.removeChannel(subscription);
      }
    };
  }, [supabase, user?.id]);

  useEffect(() => {
    if (supabase && !featureFlagService) {
      const service = new SupabaseFeatureFlagService({
        supabaseClient: supabase,
      });
      setFeatureFlagService(service);
    }
  }, [supabase]);

  const [totpQrCode, setTotpQrCode] = React.useState("");
  const [totpSecret, setTotpSecret] = React.useState("");
  const [totpUri, setTotpUri] = React.useState("");
  const [totpChallengeId, setTotpChallengeId] = React.useState("");
  const [totpChallengeExpiresAt, setTotpChallengeExpiresAt] =
    React.useState(-1);
  const [totpFactorId, setTotpFactorId] = React.useState("");

  const mustEnroll2fa = !!(totpFactorId && totpQrCode && totpSecret && totpUri);
  const mustPass2faChallenge = !!(
    totpFactorId &&
    totpChallengeId &&
    totpChallengeExpiresAt
  );

  const [reloadCounter, setReloadCounter] = React.useState(0);

  useEffect(() => {
    void (async () => {
      if (supabase && firm2faRequired) {
        const { data: factorsData, error: factorsError } =
          await supabase.auth.mfa.listFactors();

        if (factorsData && factorsError === null) {
          /*
          factorsData.all.forEach(async f => {
            const { id: factorId } = f;
            await supabase.auth.mfa.unenroll({ factorId });
          });
          */
        }

        const createChallenge = async (factorId: string) => {
          const { data: challengeData, error: challengeError } =
            await supabase.auth.mfa.challenge({ factorId });

          if (challengeError) {
            throw new Error(
              `Failed to create 2FA challenge: ${challengeError.message}`,
            );
          }

          if (challengeData) {
            const { id: challengeId, expires_at: challengeExpiresAt } =
              challengeData;

            setTotpChallengeId(challengeId);
            setTotpChallengeExpiresAt(challengeExpiresAt);
          }
        };

        const { data: assuranceLevelData, error: assuranceLevelError } =
          await supabase.auth.mfa.getAuthenticatorAssuranceLevel();

        if (assuranceLevelError) {
          throw new Error(
            `Failed to get auth assurance level: ${assuranceLevelError.message}`,
          );
        }

        if (assuranceLevelData) {
          const { currentLevel } = assuranceLevelData;

          if (currentLevel === "aal1") {
            if (
              factorsData &&
              factorsData.all.length === 1 &&
              factorsData.all[0].friendly_name === "Briefly TOTP" &&
              factorsData.all[0].status === "verified"
            ) {
              const { id: factorId } = factorsData.all[0];

              setTotpFactorId(factorId);
              await createChallenge(factorId);
            } else if (
              factorsData &&
              factorsData.all.length === 1 &&
              factorsData.all[0].friendly_name === "Briefly TOTP" &&
              factorsData.all[0].status === "unverified"
            ) {
              const { id: factorId } = factorsData.all[0];

              await supabase.auth.mfa.unenroll({ factorId });

              setReloadCounter((x) => x + 1);
            } else {
              const { data: enrollData, error: enrollError } =
                await supabase.auth.mfa.enroll({
                  factorType: "totp",
                  friendlyName: "Briefly TOTP",
                });

              if (enrollError) {
                throw new Error(`Failed to enroll 2FA: ${enrollError.message}`);
              }

              if (enrollData && "totp" in enrollData) {
                const {
                  id: factorId,
                  totp: { qr_code: qrCode, secret, uri },
                } = enrollData;

                setTotpFactorId(factorId);
                setTotpQrCode(qrCode);
                setTotpSecret(secret);
                setTotpUri(uri);

                await createChallenge(factorId);
              }
            }
          }
        }
      }
    })();
  }, [firm2faRequired, reloadCounter]);

  return (
    <FeatureFlagProvider service={featureFlagService}>
      <StatusBannerProvider>
        <div className="min-h-screen w-full">
          {(() => {
            if (mustEnroll2fa && supabase) {
              return (
                <div className="flex min-h-screen w-full flex-col justify-center">
                  <div className="flex flex-col gap-4">
                    <span className="max-w-sm self-center">
                      <b>{firmName}</b> requires all{" "}
                      {window.location.pathname.startsWith(
                        "/auth/submission-review",
                      ) ? (
                        <span>reviewers </span>
                      ) : (
                        <span>Briefly user accounts </span>
                      )}
                      to enable multi-factor authentication. To continue, open
                      or install the{" "}
                      <a
                        className="text-blue-500 transition hover:text-red-500"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.microsoft.com/en-us/account-billing/download-microsoft-authenticator-351498fc-850a-45da-b7b6-27e523b8702a"
                      >
                        Microsoft Authenticator
                      </a>{" "}
                      app and use it to scan the following QR code:
                    </span>
                    <img
                      className="h-64 w-64 self-center"
                      src={totpQrCode}
                      alt={totpUri}
                    />
                    <AuthAppCode
                      totpFactorId={totpFactorId}
                      totpChallengeId={totpChallengeId}
                      supabase={supabase}
                    />
                  </div>
                </div>
              );
            } else if (mustPass2faChallenge && supabase) {
              return (
                <div className="flex min-h-screen w-full flex-col justify-center">
                  <div className="flex flex-col gap-4">
                    <AuthAppCode
                      totpFactorId={totpFactorId}
                      totpChallengeId={totpChallengeId}
                      supabase={supabase}
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <>
                  <StatusBanner />
                  <RouterProvider router={router} />
                </>
              );
            }
          })()}
        </div>
      </StatusBannerProvider>
    </FeatureFlagProvider>
  );
};

const AuthAppCode = ({
  totpFactorId,
  totpChallengeId,
  supabase,
}: {
  totpFactorId: string;
  totpChallengeId: string;
  supabase: SupabaseClient;
}) => {
  const [totpCode, setTotpCode] = React.useState("");
  const [error, setError] = React.useState("");

  return (
    <form
      className="flex flex-col items-center gap-4"
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setTotpCode("");
        setError("");
      }}
    >
      <label
        htmlFor="authenticatorAppCode"
        className="ml-2 block text-gray-900"
      >
        Enter the code from your authenticator app:
      </label>
      <div className="flex items-center gap-4">
        <input
          id="authenticatorAppCode"
          type="text"
          placeholder="Code"
          className="input-sm rounded-md border-0 px-3 py-1.5 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400"
          value={totpCode}
          onChange={(e) => {
            setError("");
            setTotpCode(e.target.value);
          }}
        />
        <button
          className={classNames(
            "btn-primary btn btn-sm",
            !totpCode && "btn-disabled",
          )}
          onClick={() => {
            void (async () => {
              if (supabase && totpCode) {
                const { data: verifyData, error: verifyError } =
                  await supabase.auth.mfa.verify({
                    factorId: totpFactorId,
                    challengeId: totpChallengeId,
                    code: totpCode,
                  });

                if (
                  window.location.pathname.startsWith("/auth/submission-review")
                ) {
                  window.location.href = "/";
                } else if (verifyError === null && verifyData !== null) {
                  window.location.reload();
                } else if (verifyError) {
                  if (verifyError.code === "mfa_challenge_expired") {
                    window.location.reload();
                  } else if (verifyError.code === "mfa_verification_failed") {
                    setError("This code is not valid; please try again");
                  } else {
                    throw new Error(
                      `2FA verification error: ${verifyError.message}`,
                    );
                  }
                }
              }
            })();
          }}
        >
          Submit
        </button>
      </div>
      <span className={classNames("h-8 text-sm text-red-600")}>{error}</span>
      <span>
        If you need help, please contact{" "}
        <a href="mailto:support@briefly.legal">support@briefly.legal</a>
      </span>
    </form>
  );
};

export default App;
