import { type DirectoryMatter } from "@precedent/db-types/src/schema";
import { type SupabaseClient } from "@supabase/supabase-js";

export const saveMatterData = async (
  prefix: string,
  submissionId: number,
  changedMatters: Record<string, Record<string, any>>,
  firmId: string,
  userId: string,
  supabase: SupabaseClient,
): Promise<Record<string, Record<string, any>>> => {
  const updatedMatters: Record<string, Record<string, any>> = {};

  for (const [key, currentMatterData] of Object.entries(changedMatters)) {
    // Process included_order - ensure it's a valid number or null
    let includedOrder = null;
    if (
      currentMatterData.included_order !== undefined &&
      currentMatterData.included_order !== null &&
      currentMatterData.included_order !== ""
    ) {
      const orderValue = parseInt(String(currentMatterData.included_order));
      includedOrder = !isNaN(orderValue) ? orderValue : null;
    }

    const matterFields: Partial<DirectoryMatter> = {
      directory_submission_id: submissionId,
      firm_id: firmId,
      created_by: userId,
      name: currentMatterData[0],
      client_name: currentMatterData[0],
      matter_description: currentMatterData[1],
      value: currentMatterData[2],
      cross_border: currentMatterData[3],
      lead_partner_name: currentMatterData[4],
      other_team_members: currentMatterData[5],
      other_firms: currentMatterData[6],
      date_of_completion: currentMatterData[7],
      other_info: currentMatterData[8],
      is_new: false,
      is_public: prefix === "PM",
      included_order: includedOrder,
    };

    let result;
    if (currentMatterData.id && currentMatterData.id !== "") {
      const matterId = parseInt(currentMatterData.id);

      if (!isNaN(matterId)) {
        const { data } = await supabase
          .from("directory_matter")
          .update(matterFields)
          .eq("id", matterId)
          .select();
        result = data?.[0];
      } else {
        console.error(`Invalid matter ID: ${currentMatterData.id}`);
        const { data } = await supabase
          .from("directory_matter")
          .insert(matterFields)
          .select();
        result = data?.[0];
      }
    } else {
      const { data } = await supabase
        .from("directory_matter")
        .insert(matterFields)
        .select();
      result = data?.[0];
    }

    if (result) {
      updatedMatters[key] = {
        0: result.client_name,
        1: result.matter_description,
        2: result.value?.toString() || "",
        3: result.cross_border,
        4: result.lead_partner_name,
        5: result.other_team_members,
        6: result.other_firms,
        7: result.date_of_completion?.toString() || "",
        8: result.other_info,
        id: result.id.toString(),
        included_order: result.included_order ?? null,
      };
    }
  }

  return updatedMatters;
};
