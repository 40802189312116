import type { User } from "@precedent/db-types/src/schema";
import {
  type SupabaseClient,
  type Session as SupabaseSession,
} from "@supabase/supabase-js";
import { atom } from "jotai";

export const supabaseUrlAtom = atom<string | undefined>(undefined);
export const supabaseClientAtom = atom<SupabaseClient | undefined>(undefined);
export const supabaseSessionAtom = atom<SupabaseSession | null | "loading">(
  "loading",
);
export const supabaseUserAtom = atom<User | undefined>(undefined);
export const firmIdAtom = atom<string | undefined>(undefined);
export const accountTypeAtom = atom<"attorney" | "standard" | undefined>(
  undefined,
);
export const firm2faRequiredAtom = atom<boolean | undefined>(undefined);
export const firmNameAtom = atom<string | undefined>(undefined);
export const appVersionAtom = atom<string | undefined>(undefined);
export const uploadsInFlightAtom = atom(new Set<string>());

export const getSiteUrl = () => {
  let url = import.meta.env.VITE_PUBLIC_SITE_URL || "http://localhost:5173/";
  url = url.startsWith("http") ? url : `https://${url}`;
  url = url.endsWith("/") ? url : `${url}/`;
  return url;
};
