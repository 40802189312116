import { queryKeys } from "@/client";
import {
  type DirectorySubmissionFull,
  type QuestionComment,
  type QuestionComments,
  type RevisionHistory,
  type RevisionHistoryItems,
  type SubmissionFormData,
  type TableRow,
} from "@/components/DirectorySubmissions/Form/types";
import {
  loadSubmissionData,
  saveData,
  submissionToFormData,
} from "@/components/DirectorySubmissions/Form/utils";
import Spinner from "@/components/Spinner";
import { useAuth } from "@/contexts/AuthHook";
import {
  type RealtimeChannel,
  type SupabaseClient,
} from "@supabase/supabase-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import { useSnackbar } from "notistack";
import type React from "react";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";

export type SIDE_PANELS_AVAILABLE = "COMMENTS" | "REVISIONS";

interface SubmissionsContextValue {
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  isLocked: boolean;
  submissionData: DirectorySubmissionFull | null;
  formData: SubmissionFormData;
  setFormData: React.Dispatch<React.SetStateAction<SubmissionFormData>>;
  initialFormData: SubmissionFormData | null;
  error: boolean;
  isFormLocked: boolean;
  showLockWarning: () => void;
  updateLockState: (is_locked: boolean) => void;
  sidePanelOpen: SIDE_PANELS_AVAILABLE | null;
  openSidePanel: (panelName: SIDE_PANELS_AVAILABLE, questionId: string) => void;
  closeSidePanel: () => void;
  submissionComments: QuestionComments;
  submissionRevisions: RevisionHistoryItems;
  activeCommentQuestion: string | null;
  setActiveCommentQuestion: (sectionId: string | null) => void;
  saveField: (fieldId: string, value: any) => Promise<void>;
  pendingSaves: Set<string>;
  approvedQuestions: Set<string>;
  isQuestionApproved: (questionId: string) => boolean;
}

export const SubmissionsContext = createContext<SubmissionsContextValue | null>(
  null,
);

export const SubmissionsProvider = ({
  children,
  submissionId: submissionIdProp,
}: {
  children: React.ReactNode;
  submissionId?: string;
}) => {
  const { submissionId: submissionIdFromParams } = useParams();
  const submissionId = submissionIdProp || submissionIdFromParams;
  const { supabase, firmId, userId } = useAuth();
  const [error, setError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [formData, setFormData] = useState<SubmissionFormData>({});
  const [activeCommentQuestion, setActiveCommentQuestion] = useState<
    string | null
  >(null);
  const [sidePanelOpen, setSidePanelOpen] =
    useState<SIDE_PANELS_AVAILABLE | null>(null);

  const initialFormDataRef = useRef<SubmissionFormData | null>(null);
  const previousSubmissionIdRef = useRef<string | undefined>(undefined);
  const savingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const [isLocked, setIsLocked] = useState(false);

  const savingSnackbarShownRef = useRef(false);
  const lockedSnackbarShownRef = useRef(false);

  const [pendingSaves, setPendingSaves] = useState<Set<string>>(new Set());
  const saveTimersRef = useRef<Record<string, NodeJS.Timeout>>({});
  const savingQueueRef = useRef<Map<string, any>>(new Map());
  const saveVersionRef = useRef<Record<string, number>>({});
  const [approvedQuestions, setApprovedQuestions] = useState<Set<string>>(
    new Set(),
  );

  const {
    data: submissionData,
    isLoading,
    isRefetching,
  } = useQuery({
    queryKey: queryKeys.directorySubmission(submissionId ?? ""),
    queryFn: async (): Promise<DirectorySubmissionFull> => {
      if (!submissionId) {
        throw new Error("No submissionId");
      }
      try {
        return await loadSubmissionData(
          submissionId,
          supabase as SupabaseClient,
        );
      } catch (err) {
        setError(true);
        throw err;
      }
    },
    enabled: !!submissionId && !!supabase,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const { data: submissionComments = {} } = useQuery({
    queryKey: queryKeys.directorySubmissionComments(submissionId as string),
    queryFn: async (): Promise<QuestionComments> => {
      const { data, error } = await (supabase as SupabaseClient)
        .from("directory_submission_question_comment")
        .select(
          `
            id,
            created_at,
            text,
            question_id,
            type,
            created_by (
              id,
              first_name,
              last_name,
              email,
              avatar_url
            ),
            firm_id
          `,
        )
        .eq("submission_id", submissionId)
        .returns<QuestionComment[]>();

      if (error) {
        throw new Error(error.message);
      }

      const keyedComments = (data ?? []).reduce<QuestionComments>(
        (acc, comment) => {
          if (!acc[comment.question_id]) {
            acc[comment.question_id] = [];
          }
          acc[comment.question_id] = [
            ...acc[comment.question_id],
            {
              id: comment.id,
              text: comment.text,
              created_at: comment.created_at,
              question_id: comment.question_id,
              type: comment.type,
              created_by: {
                id: comment.created_by.id,
                first_name: comment.created_by.first_name,
                last_name: comment.created_by.last_name,
                email: comment.created_by.email,
                avatar_url: comment.created_by.avatar_url,
              },
              firm_id: comment.firm_id,
            },
          ];
          return acc;
        },
        {},
      );

      return keyedComments;
    },
    enabled: !!submissionId && !!supabase,
    refetchOnWindowFocus: false,
    staleTime: 0,
    initialData: {},
  });

  const { data: submissionRevisions = {} } = useQuery({
    queryKey: queryKeys.directorySubmissionRevisions(submissionId as string),
    queryFn: async (): Promise<Record<string, RevisionHistory[]>> => {
      const { data, error } = await (supabase as SupabaseClient)
        .from("directory_submission_question_revision_history")
        .select(
          `
            id,
            created_at,
            previous,
            new,
            is_current,
            question_id,
            column_index,
            row_index,
            created_by (
              id,
              first_name,
              last_name,
              email,
              avatar_url
            )
          `,
        )
        .eq("submission_id", submissionId)
        .returns<RevisionHistory[]>();

      if (error) {
        throw new Error(error.message);
      }

      const keyedRevisions = (data ?? []).reduce<
        Record<string, RevisionHistory[]>
      >((acc, revision) => {
        if (!acc[revision.question_id]) {
          acc[revision.question_id] = [];
        }
        acc[revision.question_id] = [...acc[revision.question_id], revision];
        return acc;
      }, {});
      return keyedRevisions;
    },
    enabled: !!submissionId && !!supabase,
    refetchOnWindowFocus: false,
    staleTime: 0,
    initialData: {},
  });

  useEffect(() => {
    setIsSaving(isRefetching);
  }, [isRefetching]);

  useEffect(() => {
    if (submissionData) {
      const newInitialFormData = submissionToFormData(submissionData);
      setFormData(newInitialFormData);
      initialFormDataRef.current = newInitialFormData;
      setIsLocked(submissionData.is_locked);

      const ensureRequiredTablesExist = async () => {
        if (!submissionId || !firmId || !supabase) return;

        try {
          await supabase.from("directory_submission_overview").upsert(
            {
              firm_id: firmId,
              directory_submission_id: parseInt(submissionId),
              firm_name: submissionData.firm_name,
            },
            {
              onConflict: "directory_submission_id",
            },
          );
        } catch (error) {
          console.error("Error ensuring required tables exist:", error);
        }
      };

      void ensureRequiredTablesExist();
    }
  }, [submissionData, submissionId, firmId, supabase]);

  useEffect(() => {
    if (submissionId === undefined) {
      setFormData({});
      initialFormDataRef.current = null;
      setIsLocked(false);
    }
  }, [submissionId]);

  useEffect(() => {
    const saveAllChanges = async () => {
      if (
        previousSubmissionIdRef.current &&
        previousSubmissionIdRef.current !== submissionId &&
        initialFormDataRef.current
      ) {
        const changedFields = Object.keys(formData).filter(
          (key) => !isEqual(formData[key], initialFormDataRef.current![key]),
        );

        if (changedFields.length > 0) {
          setIsSaving(true);
          try {
            await saveData({
              supabase,
              firmId,
              userId,
              submissionId: parseInt(previousSubmissionIdRef.current),
              enqueueSnackbar,
              formData,
              initialFormData: initialFormDataRef.current,
              changedFields,
            });
            enqueueSnackbar("Changes saved successfully", {
              variant: "success",
            });
          } catch (saveError) {
            enqueueSnackbar("Failed to save changes", { variant: "error" });
          } finally {
            setIsSaving(false);
          }

          void queryClient.invalidateQueries({
            queryKey: queryKeys.directorySubmission(
              previousSubmissionIdRef.current ?? "",
            ),
          });
        }
      }
      previousSubmissionIdRef.current = submissionId;
    };

    void saveAllChanges();
  }, [
    submissionId,
    supabase,
    firmId,
    userId,
    enqueueSnackbar,
    formData,
    queryClient,
  ]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      const hasUnsavedChanges =
        initialFormDataRef.current &&
        Object.keys(formData).some(
          (key) => !isEqual(formData[key], initialFormDataRef.current![key]),
        );

      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formData]);

  useEffect(() => {
    if (isSaving) {
      if (savingTimeoutRef.current) {
        clearTimeout(savingTimeoutRef.current);
      }

      savingTimeoutRef.current = setTimeout(() => {
        if (isSaving) {
          enqueueSnackbar(
            "Saving is taking longer than expected. Please try again.",
            { variant: "error" },
          );
          setIsSaving(false);
        }
      }, 10000);
    } else {
      if (savingTimeoutRef.current) {
        clearTimeout(savingTimeoutRef.current);
        savingTimeoutRef.current = null;
      }
    }

    return () => {
      if (savingTimeoutRef.current) {
        clearTimeout(savingTimeoutRef.current);
      }
    };
  }, [isSaving, enqueueSnackbar]);

  const showLockWarning = useCallback(() => {
    if (isSaving) {
      if (!savingSnackbarShownRef.current) {
        enqueueSnackbar("Just a moment, saving in progress", {
          variant: "warning",
          anchorOrigin: { horizontal: "right", vertical: "bottom" },
        });
        savingSnackbarShownRef.current = true;
      }
    } else if (isLocked) {
      if (!lockedSnackbarShownRef.current) {
        enqueueSnackbar(
          "This submission has been locked. Please unlock to enable editing.",
          {
            variant: "warning",
            anchorOrigin: { horizontal: "right", vertical: "bottom" },
          },
        );
        lockedSnackbarShownRef.current = true;
      }
    }
  }, [isSaving, isLocked, enqueueSnackbar]);

  useEffect(() => {
    if (!isSaving) {
      savingSnackbarShownRef.current = false;
    }
    if (!isLocked) {
      lockedSnackbarShownRef.current = false;
    }
  }, [isSaving, isLocked]);

  const isFormLocked = isSaving || isLocked;

  const updateLockStateMutation = useMutation({
    mutationFn: async (isLocked: boolean) => {
      const { error } = await (supabase as SupabaseClient)
        .from("directory_submission")
        .update({ is_locked: isLocked })
        .eq("id", submissionId);
      if (error) throw new Error(error.message);
    },
    onSuccess: (_, isLocked) => {
      queryClient.setQueryData(
        queryKeys.directorySubmission(submissionId ?? ""),
        (oldData: any) => ({
          ...oldData,
          is_locked: isLocked,
        }),
      );
      setIsLocked(isLocked);
    },
    onError: (error) => {
      enqueueSnackbar(`Failed to update lock state: ${error.message}`, {
        variant: "error",
      });
    },
  });

  // for troubleshooting "Unsaved Changes"
  useEffect(() => {
    if (!initialFormDataRef.current) {
      return;
    }

    const allKeys = [
      ...new Set([
        ...Object.keys(formData),
        ...Object.keys(initialFormDataRef.current),
      ]),
    ];

    type FormDataDifferences = Record<
      string,
      {
        formData: SubmissionFormData[keyof SubmissionFormData];
        initialFormData: SubmissionFormData[keyof SubmissionFormData];
      }
    >;

    const differences = allKeys.reduce<FormDataDifferences>((acc, key) => {
      if (!isEqual(formData[key], initialFormDataRef.current![key])) {
        acc[key] = {
          formData: formData[key],
          initialFormData: initialFormDataRef.current![key],
        };
      }
      return acc;
    }, {});

    if (Object.keys(differences).length > 0) {
      console.log(
        "Found differences between formData and initialFormDataRef.current:",
      );
      console.table(differences);
    } else {
      console.log(
        "No differences found between formData and initialFormDataRef.current",
      );
    }
  }, [formData]);

  const openSidePanel = useCallback(
    (panelName: SIDE_PANELS_AVAILABLE, questionId: string) => {
      setSidePanelOpen(panelName);
      setActiveCommentQuestion(questionId);
    },
    [],
  );

  const closeSidePanel = useCallback(() => {
    setSidePanelOpen(null);
    setActiveCommentQuestion(null);
  }, []);

  // Debounced save function
  const saveField = useCallback(
    async (fieldId: string, value: any) => {
      if (!submissionId || !initialFormDataRef.current) return;

      // Check if this is a table field and if so, extract cell coordinates
      const isTableField =
        Array.isArray(value) &&
        value.length > 0 &&
        typeof value[0] === "object" &&
        value[0] !== null &&
        "value" in value[0];

      let cellCoordinates: string | null = null;
      let originalValue = value;

      // Define type for cell update metadata
      interface CellUpdateMetadata {
        rowIndex: number;
        colIndex: number;
        cellValue: string | boolean | null;
      }

      // For table fields, we need to track at the cell level
      if (isTableField) {
        // Check if this is a cell-specific update by looking for metadata
        const valueWithMeta = value as any;
        if (
          valueWithMeta._cellUpdate &&
          typeof valueWithMeta._cellUpdate === "object"
        ) {
          const { rowIndex, colIndex, cellValue } =
            valueWithMeta._cellUpdate as CellUpdateMetadata;
          cellCoordinates = `${fieldId}:${rowIndex}:${colIndex}`;

          // Store original table but apply the cell-specific change
          originalValue = [...((formData[fieldId] as TableRow[]) || [])];

          // Make sure we have the row
          if (!originalValue[rowIndex]) {
            originalValue[rowIndex] = { id: rowIndex, value: [] };
          }

          // Clone the row's value array to avoid mutation issues
          const newRowValue = [...(originalValue[rowIndex].value || [])];
          newRowValue[colIndex] = cellValue;

          // Update the row with new values
          originalValue[rowIndex] = {
            ...originalValue[rowIndex],
            value: newRowValue,
          };

          // Remove the metadata before saving
          delete valueWithMeta._cellUpdate;
        }
      }

      // The unique identifier for this save operation - either cell coordinates or field ID
      const saveKey = cellCoordinates || fieldId;

      // Cancel any pending save for this field/cell
      if (saveTimersRef.current[saveKey]) {
        clearTimeout(saveTimersRef.current[saveKey]);
      }

      // Update version for this field/cell
      saveVersionRef.current[saveKey] =
        (saveVersionRef.current[saveKey] || 0) + 1;
      const currentVersion = saveVersionRef.current[saveKey];

      // Queue the change
      savingQueueRef.current.set(saveKey, originalValue);

      // Add field to pending saves
      setPendingSaves((prev) => {
        const newSet = new Set(prev);
        newSet.add(saveKey);
        return newSet;
      });

      // Debounce the save (500ms)
      saveTimersRef.current[saveKey] = setTimeout(() => {
        // Check if this save is still relevant
        if (currentVersion !== saveVersionRef.current[saveKey]) {
          return; // A newer save has been queued, skip this one
        }

        // Get the actual field ID (strip cell coordinates if present)
        const actualFieldId = cellCoordinates ? fieldId : saveKey;

        // Create a batch of changes to save
        const changedFields = [actualFieldId];

        // Create a snapshot of form data with our specific change
        const changesSnapshot = { ...formData };

        // For cell updates, make sure we're sending the entire updated table
        if (cellCoordinates) {
          changesSnapshot[actualFieldId] = savingQueueRef.current.get(saveKey);
        }

        // Execute the save in the background
        void (async () => {
          try {
            const result = await saveData({
              supabase,
              firmId,
              userId,
              submissionId: parseInt(submissionId),
              enqueueSnackbar,
              formData: changesSnapshot,
              initialFormData: initialFormDataRef.current!,
              changedFields,
              invalidFieldCount: undefined,
              skipQueryInvalidation: true, // Don't invalidate queries, we'll handle updates optimistically
            });

            // Update the initialFormData to reflect what was saved
            if (result && typeof result === "object") {
              Object.keys(result).forEach((key) => {
                if (initialFormDataRef.current && result[key] !== undefined) {
                  initialFormDataRef.current[key] = structuredClone(
                    result[key] as SubmissionFormData[keyof SubmissionFormData],
                  );
                }
              });
            }

            // Remove from pending and queue only after successful save
            savingQueueRef.current.delete(saveKey);
            setPendingSaves((prev) => {
              const newSet = new Set(prev);
              newSet.delete(saveKey);
              return newSet;
            });
          } catch (error) {
            console.error("Error saving field:", error);
            enqueueSnackbar("Failed to save changes", {
              variant: "error",
              anchorOrigin: { horizontal: "right", vertical: "bottom" },
            });

            // Clear pending status on error
            savingQueueRef.current.delete(saveKey);
            setPendingSaves((prev) => {
              const newSet = new Set(prev);
              newSet.delete(saveKey);
              return newSet;
            });
          }
        })();
      }, 500);
    },
    [submissionId, formData, supabase, firmId, userId, enqueueSnackbar],
  );

  useEffect(() => {
    if (submissionComments && submissionRevisions) {
      const approved = new Set<string>();

      Object.entries(submissionComments).forEach(([questionId, comments]) => {
        const approvalComments = comments.filter(
          (comment) => comment.type === "approval",
        );
        if (approvalComments.length === 0) return;

        const latestApproval = approvalComments.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        )[0];

        const revisions = submissionRevisions[questionId] || [];
        const latestRevision = revisions.sort(
          (a, b) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
        )[0];

        if (
          !latestRevision ||
          new Date(latestApproval.created_at) >
            new Date(latestRevision.created_at)
        ) {
          approved.add(questionId);
        }
      });

      setApprovedQuestions(approved);
    }
  }, [submissionComments, submissionRevisions]);

  const isQuestionApproved = useCallback(
    (questionId: string) => approvedQuestions.has(questionId),
    [approvedQuestions],
  );

  // Add subscriptions for comments and revisions
  useEffect(() => {
    let commentsSubscription: RealtimeChannel | null = null;
    let revisionsSubscription: RealtimeChannel | null = null;
    let mattersSubscription: RealtimeChannel | null = null;

    const subscribeToChannel = (channel: RealtimeChannel): Promise<void> => {
      return new Promise((resolve, reject) => {
        channel.subscribe((status, err) => {
          if (status === "SUBSCRIBED") {
            resolve();
          } else if (status === "CHANNEL_ERROR" || status === "TIMED_OUT") {
            reject(err);
          }
        });
      });
    };

    const subscribeToComments = async () => {
      if (!supabase || !submissionId) return;

      try {
        if (commentsSubscription) {
          await supabase.removeChannel(commentsSubscription);
          commentsSubscription = null;
        }

        commentsSubscription = supabase
          .channel(`submission-comments:${submissionId}`)
          .on(
            "postgres_changes",
            {
              event: "INSERT",
              schema: "public",
              table: "directory_submission_question_comment",
              filter: `submission_id=eq.${submissionId}`,
            },
            (payload) => {
              // When a new comment is inserted, simply invalidate the query to refetch with all joins
              // This ensures we get the full comment data structure including created_by
              void queryClient.invalidateQueries({
                queryKey: queryKeys.directorySubmissionComments(submissionId),
              });
            },
          );

        await subscribeToChannel(commentsSubscription);
      } catch (error) {
        console.error("Error setting up comments subscription:", error);
        commentsSubscription = null;
      }
    };

    const subscribeToRevisions = async () => {
      if (!supabase || !submissionId) return;

      try {
        if (revisionsSubscription) {
          await supabase.removeChannel(revisionsSubscription);
          revisionsSubscription = null;
        }

        revisionsSubscription = supabase
          .channel(`submission-revisions:${submissionId}`)
          .on(
            "postgres_changes",
            {
              event: "INSERT",
              schema: "public",
              table: "directory_submission_question_revision_history",
              filter: `submission_id=eq.${submissionId}`,
            },
            (payload) => {
              queryClient.setQueryData(
                queryKeys.directorySubmissionRevisions(submissionId),
                (oldData: RevisionHistoryItems | undefined) => {
                  const newData = { ...(oldData || {}) };
                  const revision = payload.new as RevisionHistory;
                  const questionId = revision.question_id;

                  if (!newData[questionId]) {
                    newData[questionId] = [];
                  }

                  // Prevent duplicate inserts
                  if (newData[questionId].some((r) => r.id === revision.id)) {
                    return newData;
                  }

                  newData[questionId] = [...newData[questionId], revision];
                  return newData;
                },
              );
            },
          );

        await subscribeToChannel(revisionsSubscription);
      } catch (error) {
        console.error("Error setting up revisions subscription:", error);
        revisionsSubscription = null;
      }
    };

    const subscribeToMatters = async () => {
      if (!supabase || !submissionId) return;

      try {
        if (mattersSubscription) {
          await supabase.removeChannel(mattersSubscription);
          mattersSubscription = null;
        }

        mattersSubscription = supabase
          .channel(`directory-matters:${submissionId}`)
          .on(
            "postgres_changes",
            {
              event: "INSERT",
              schema: "public",
              table: "directory_matter",
            },
            (payload) => {
              // When a new matter is inserted, invalidate the relevant query
              // This will trigger a refetch of any data that depends on directory matters
              void queryClient.invalidateQueries({
                queryKey: queryKeys.directorySubmission(submissionId ?? ""),
              });

              // Notify the user about the new matter
              enqueueSnackbar("A new directory matter has been added", {
                variant: "info",
                anchorOrigin: { horizontal: "right", vertical: "bottom" },
              });
            },
          );

        await subscribeToChannel(mattersSubscription);
      } catch (error) {
        console.error("Error setting up matters subscription:", error);
        mattersSubscription = null;
      }
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const channels = (supabase as SupabaseClient).getChannels();

        // Check comments subscription
        const commentsChannelExists = channels.find(
          (channel) => channel.topic === `submission-comments:${submissionId}`,
        );
        if (
          !commentsChannelExists ||
          commentsChannelExists.state !== "joined"
        ) {
          void subscribeToComments();
        }

        // Check revisions subscription
        const revisionsChannelExists = channels.find(
          (channel) => channel.topic === `submission-revisions:${submissionId}`,
        );
        if (
          !revisionsChannelExists ||
          revisionsChannelExists.state !== "joined"
        ) {
          void subscribeToRevisions();
        }

        // Check matters subscription
        const mattersChannelExists = channels.find(
          (channel) => channel.topic === `directory-matters:${submissionId}`,
        );
        if (!mattersChannelExists || mattersChannelExists.state !== "joined") {
          void subscribeToMatters();
        }
      }
    };

    void subscribeToComments();
    void subscribeToRevisions();
    void subscribeToMatters();

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      if (commentsSubscription && supabase) {
        void supabase.removeChannel(commentsSubscription);
        commentsSubscription = null;
      }
      if (revisionsSubscription && supabase) {
        void supabase.removeChannel(revisionsSubscription);
        revisionsSubscription = null;
      }
      if (mattersSubscription && supabase) {
        void supabase.removeChannel(mattersSubscription);
        mattersSubscription = null;
      }
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [submissionId, supabase, queryClient]);

  return (
    <SubmissionsContext.Provider
      value={{
        isSaving,
        setIsSaving,
        isLocked,
        submissionData: submissionData ?? null,
        formData,
        setFormData,
        initialFormData: initialFormDataRef.current,
        error,
        isFormLocked,
        showLockWarning,
        updateLockState: updateLockStateMutation.mutate,
        sidePanelOpen,
        openSidePanel,
        closeSidePanel,
        submissionComments,
        submissionRevisions,
        activeCommentQuestion,
        setActiveCommentQuestion,
        saveField,
        pendingSaves,
        approvedQuestions,
        isQuestionApproved,
      }}
    >
      {submissionId && (isLoading || !submissionData) ? <Spinner /> : children}
    </SubmissionsContext.Provider>
  );
};

export const useSubmissions = () => {
  const context = useContext(SubmissionsContext);
  if (!context) {
    throw new Error("useSubmissions must be used within a SubmissionsProvider");
  }
  return context;
};
